var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col w-full h-auto mb-12"
  }, [_c('div', {
    staticClass: "flex w-full h-auto justify-center items-center"
  }, [_c('span', {
    staticClass: "Helvetica text-2xl font-bold text-green-500 justify-center normal-case"
  }, [_vm._v(_vm._s(_vm.$t("expedition_information")))])]), _c('div', {
    staticClass: "px-6 mt-5"
  }, [_c('div', {
    staticClass: "flex bg-white w-full space-x-2"
  }, [_c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("package_information")) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-500"
  }), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "numberOfPackages"
    }
  }, [_vm._v(_vm._s(_vm.$t("number_of_packages")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.numberOfPackages,
      expression: "item.numberOfPackages"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "min": "1",
      "type": "number",
      "disabled": ""
    },
    domProps: {
      "value": _vm.item.numberOfPackages
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "numberOfPackages", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Weight"
    }
  }, [_vm._v(_vm._s(_vm.$t("weight")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('div', {
    staticClass: "w-full rounded-md inline-flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.weight,
      expression: "item.weight"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "Weight",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "number",
      "min": "1",
      "disabled": ""
    },
    domProps: {
      "value": _vm.item.weight
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "weight", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "rounded-md w-1/12 bg-gray-200 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("kg")) + " ")])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Contry"
    }
  }, [_vm._v(_vm._s(_vm.$t("from")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.country,
      expression: "item.country"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.item.country
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "country", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country_to"
    }
  }, [_vm._v(_vm._s(_vm.$t("to")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.country_to,
      expression: "item.country_to"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.item.country_to
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "country_to", $event.target.value);
      }
    }
  })])])])])])]), _c('div', {
    staticClass: "flow-root w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-5"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("update_expedition_information")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.status,
      expression: "item.status"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    style: _vm.colorStatus(_vm.item.status),
    attrs: {
      "name": "status"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.item, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChangeStatus($event);
      }]
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _vm._l(_vm.statusExpedition, function (value, index) {
    return _c('option', {
      key: index,
      staticClass: "bg-white text-gray-600",
      domProps: {
        "value": value
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  })], 2)])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "arrivalDate"
    }
  }, [_vm._v(_vm._s(_vm.$t("arrival_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.arrivalDate,
      expression: "item.arrivalDate"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "arrivalDate",
      "type": "datetime-local"
    },
    domProps: {
      "value": _vm.item.arrivalDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "arrivalDate", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "date"
    }
  }, [_vm._v(_vm._s(_vm.$t("expedition_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formattedDate,
      expression: "formattedDate"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "date",
      "type": "datetime-local",
      "disabled": ""
    },
    domProps: {
      "value": _vm.formattedDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.formattedDate = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "transportmode"
    }
  }, [_vm._v(_vm._s(_vm.$t("transport_mode")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.transportMode,
      expression: "item.transportMode"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    attrs: {
      "id": "type",
      "name": "transportMode",
      "disabled": "",
      "autocomplete": "type"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.item, "transportMode", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("transport_mode")) + " ")]), _vm._l(_vm.transports, function (trans, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": trans
      }
    }, [_vm._v(" " + _vm._s(trans) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("expedition_fees")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.fees,
      expression: "item.fees"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "fees",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.item.fees
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "fees", $event.target.value);
      }
    }
  })])])])])])])]), _c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg",
    attrs: {
      "id": _vm.item._id
    }
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(2), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("expedition_products")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("image")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_sent")) + " ")]), _c('th', {
    staticClass: "p-2 justify-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_received")) + " ")])])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.item.details, function (it) {
    return _c('tr', {
      key: it._id,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity.sent,
        expression: "it.quantity.sent"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "sent",
        "disabled": _vm.CheckExpeditionRecived,
        "type": "number",
        "min": "0"
      },
      domProps: {
        "value": it.quantity.sent
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.quantity, "sent", $event.target.value);
        }
      }
    })]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('table', {
      staticClass: "min-w-full"
    }, [_vm._m(3, true), _c('tbody', {
      staticClass: "bg-white"
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity.received,
        expression: "it.quantity.received"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Received",
        "disabled": _vm.CheckExpeditionRecived,
        "type": "number",
        "min": "0",
        "max": it.quantity.sent
      },
      domProps: {
        "value": it.quantity.received
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.quantity, "received", $event.target.value);
        }
      }
    })])]), _c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity.defective,
        expression: "it.quantity.defective"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Defective",
        "disabled": _vm.CheckExpeditionRecived,
        "type": "number",
        "min": "0",
        "max": it.quantity.sent
      },
      domProps: {
        "value": it.quantity.defective
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.quantity, "defective", $event.target.value);
        }
      }
    })])])])])])])]);
  }), 0)])])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_grocery_store")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" Ok/Good ")]), _c('th', {
    staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" Defective ")])])]);

}]

export { render, staticRenderFns }