<template>
  <div class="flex flex-col w-full h-auto mb-12">
    <!-- Header -->
    <div class="flex w-full h-auto justify-center items-center">
      <span
        class="Helvetica text-2xl font-bold text-green-500 justify-center normal-case"
        >{{ $t("expedition_information") }}</span
      >
    </div>
    <!-- ******************************** -->
    <div class="px-6 mt-5">
      <div class="flex bg-white w-full space-x-2">
        <div
          class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
        >
          <div class="flex w-full mt-1">
            <span class="rounded-full h-12 w-12 bg-gray-200">
              <i class="material-icons p-3 text-green-400">person</i>
            </span>
            <div class="ml-5 w-full pb-4">
              <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                {{ $t("package_information") }}
              </div>
              <div class="text-sm font-light text-gray-500"></div>
              <div class="w-full space-y-4">
                <div class="flex">
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="numberOfPackages"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("number_of_packages") }}
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <input
                        min="1"
                        type="number"
                        v-model="item.numberOfPackages"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                        disabled
                      />
                    </p>
                  </div>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="Weight" class="bg-white text-gray-700 px-1"
                        >{{ $t("weight") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <div class="w-full rounded-md inline-flex">
                    <input
                      id="Weight"
                      autocomplete="false"
                      tabindex="0"
                      type="number"
                      min="1"
                      v-model="item.weight"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                      disabled
                    />
                    <div class="rounded-md w-1/12 bg-gray-200 text-center">
                      {{ $t("kg") }}
                    </div>
                  </div>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="Contry" class="bg-white text-gray-700 px-1"
                        >{{ $t("from") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <p>
                    <input
                      autocomplete="false"
                      tabindex="0"
                      type="text"
                      v-model="item.country"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                      disabled
                    />
                  </p>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label
                        for="country_to"
                        class="bg-white text-gray-700 px-1"
                        >{{ $t("to") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <p>
                    <input
                      autocomplete="false"
                      tabindex="0"
                      type="text"
                      v-model="item.country_to"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                      disabled
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ****************************** Carrier Information and expedition info *********************************************-->
        <div
          class="flow-root w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
        >
          <div class="flex w-full mt-5">
            <span class="rounded-full h-12 w-12 bg-gray-200">
              <i class="material-icons p-3 text-green-400"
                >local_grocery_store</i
              >
            </span>
            <div class="ml-5 w-full">
              <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                {{ $t("update_expedition_information") }}
              </div>
              <div class="w-full space-y-4">
                <div class="flex">
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="status" class="bg-white text-gray-700 px-1"
                          >{{ $t("status") }}
                          <span :class="$colors.required">*</span>
                        </label>
                      </p>
                    </div>
                    <p>
                      <select
                        name="status"
                        @change="onChangeStatus($event)"
                        :style="colorStatus(item.status)"
                        v-model="item.status"
                        class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                      >
                        <option class="bg-white" selected :value="null">
                          {{ $t("status") }}
                        </option>
                        <option
                          class="bg-white text-gray-600"
                          v-for="(value, index) in statusExpedition"
                          :key="index"
                          :value="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                    </p>
                  </div>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label
                        for="arrivalDate"
                        class="bg-white text-gray-700 px-1"
                        >{{ $t("arrival_date") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <p>
                    <input
                      id="arrivalDate"
                      type="datetime-local"
                      v-model="item.arrivalDate"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                    />
                  </p>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="date" class="bg-white text-gray-700 px-1"
                        >{{ $t("expedition_date") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <p>
                    <input
                      id="date"
                      type="datetime-local"
                      v-model="formattedDate"
                      disabled
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                    />
                  </p>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label
                        for="transportmode"
                        class="bg-white text-gray-700 px-1"
                        >{{ $t("transport_mode") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <p>
                    <select
                      id="type"
                      name="transportMode"
                      disabled
                      autocomplete="type"
                      v-model="item.transportMode"
                      class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                    >
                      <option class="bg-white" selected :value="null">
                        {{ $t("transport_mode") }}
                      </option>
                      <option
                        v-for="(trans, index) in transports"
                        :key="index"
                        :value="trans"
                      >
                        {{ trans }}
                      </option>
                    </select>
                  </p>
                </div>
                <div
                  class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label
                        for="customerPhone"
                        class="bg-white text-gray-700 px-1"
                        >{{ $t("expedition_fees") }}
                        <span :class="$colors.required">*</span></label
                      >
                    </p>
                  </div>
                  <p>
                    <input
                      id="fees"
                      autocomplete="false"
                      tabindex="0"
                      type="number"
                      min="0"
                      v-model="item.fees"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                    />
                  </p>
                </div>
                <!-- <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="customerPhone" class="bg-white text-gray-700 px-1">Paid 
                                                <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                        <p class="flex items-center justify-center w-full">
                                     
                                            <label  :for="item._id" class="flex items-center cursor-pointer">
                                            <div class="relative">
                                                <input :id="item._id" type="checkbox" class="hidden" v-model="item.paid" />
                                                <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                                                <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                                            </div>
                                            
                                            <div class="ml-3 text-gray-700">{{ item.paid  }}</div>
                                            </label>
                                        </p>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--*******************  Product Order ***************************************-->
      <div
        :id="item._id"
        class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
      >
        <div class="flex w-full mt-1">
          <span class="rounded-full h-12 w-12 bg-gray-200">
            <i class="material-icons p-3 text-green-400">local_mall</i>
          </span>
          <div class="ml-5 w-full pb-4">
            <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
              {{ $t("expedition_products") }}
            </div>
            <div class="w-full space-y-4">
              <div class="w-full">
                <table class="min-w-full w-full">
                  <thead class="text-white">
                    <tr
                      class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                    >
                      <th class="p-2 justify-center">{{ $t("image") }}</th>
                      <th class="p-2 justify-center">{{ $t("name") }}</th>
                      <th class="p-2 justify-center">
                        {{ $t("quantity_sent") }}
                      </th>
                      <th colspan="2" class="p-2 justify-center">
                        {{ $t("quantity_received") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="flex-1 sm:flex-none">
                    <tr
                      v-for="it in item.details"
                      :key="it._id"
                      class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                    >
                      <td
                        class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
                      >
                        <div>
                          <img
                            :alt="it.product.name"
                            :src="
                              it.product.picture ||
                              'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                            "
                            class="h-16 w-16 rounded-md mx-auto"
                          />
                        </div>
                      </td>
                      <td
                        class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
                      >
                        <b>{{ it.product.name }}</b>
                      </td>
                      <td
                        class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
                      >
                        <input
                          id="sent"
                          :disabled="CheckExpeditionRecived"
                          type="number"
                          min="0"
                          v-model="it.quantity.sent"
                          class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
                        />
                      </td>
                      <td
                        class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
                      >
                        <table class="min-w-full">
                          <thead>
                            <tr>
                              <th
                                class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                              >
                                Ok/Good
                              </th>
                              <th
                                class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
                              >
                                Defective
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white">
                            <tr>
                              <td class="px-6 py-4 border whitespace-no-wrap">
                                <div class="flex items-center">
                                  <input
                                    id="Received"
                                    :disabled="CheckExpeditionRecived"
                                    type="number"
                                    min="0"
                                    :max="it.quantity.sent"
                                    v-model="it.quantity.received"
                                    class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4 border whitespace-no-wrap">
                                <div class="flex items-center">
                                  <input
                                    id="Defective"
                                    :disabled="CheckExpeditionRecived"
                                    type="number"
                                    min="0"
                                    :max="it.quantity.sent"
                                    v-model="it.quantity.defective"
                                    class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: { required: true, type: Object },
    item: { default: [] },
    index: { type: Number },
    statusExpedition: { type: Array },
    transports: { type: Array },
    CheckExpeditionRecived: { required: false },
  },
  data() {
    return {
      options: this.$countries,
    };
  },
  async mounted() {
    await this.CheckQteSentExist();
  },
  methods: {
    scrollTo(hash) {
      location.hash = "#" + hash;
    },
    onChangeStatus(event) {
      if (event.target.value === "received") {
        this.scrollTo(this.item._id);
      }
    },
    colorStatus(status) {
      switch (status) {
        case "expedited":
          return "color: rgb(233 147 19); border-color: rgb(233 147 19);";
          break;
        case "received":
          return "color:rgb(58 217 18); border-color: rgb(58 217 18);";
          break;
        case "cancelled":
          return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);";
          break;
      }
    },
    CheckQteSentExist: function () {
      this.$emit("CheckQteSentExist", this.item);
    },
  },
  computed: {
    formattedDate() {
      return this.$moment(this.item.date).format("yyyy-MM-DDTHH:mm");
    },
    formattedDateArrival() {
      return this.$moment(item.arrivalDate).format("yyyy-MM-DDTHH:mm");
    },
  },
  async mounted() {},
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
</style>
